<template>
  <div>
    <CoolLightBox :items="images" :index="indexImage" @close="indexImage = null"></CoolLightBox>
    <b-row style="margin-bottom: 10px">
      <b-col sm="6">
        <b-input-group size="sm">
          <b-input-group-prepend is-text>ACCOUNT</b-input-group-prepend>
            <b-form-input v-model="accountName" readonly />
        </b-input-group>
      </b-col>
      <b-col sm="6">
        <b-input-group size="sm">
          <b-input-group-prepend is-text>CLIENT</b-input-group-prepend>
            <b-form-input v-model="leadName" readonly />
        </b-input-group>
      </b-col>
    </b-row>
    <b-table
        show-empty
        sticky-header
        small
        responsive="sm"
        :fields="fieldsQuicks"
        :items="items"
        :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <!-- Column: Send By -->
      <template #cell(send_by)="data">
        <span>{{ data.item.user_name }}</span>
        <br />
        <span>{{ data.item.created_at }}</span>
      </template>

      <!-- Column: Number -->
      <template #cell(number)="data">
        <div v-html="data.item.number" />
      </template>

      <!-- Column: Content -->
      <template #cell(content)="data">
        <div v-html="data.item.content" />
      </template>

      <!-- Column: Action -->
      <template #cell(image)="data">
        <div class="text-center">
          <b-icon
              icon="image-fill"
              font-scale="1.5"
              :class="data.item.image? 'cursor-pointer text-primary': 'text-secondary'"
              @click="indexImage = data.index"
          />
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import ClientService from '../services/clients.service';
export default {
  components: {
    CoolLightBox
  },
  props: {
    accountName: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    leadName: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token"
    })
  },
  data() {
    return {
      userId: null,
      roleId: null,
      isBusy: false,
      fieldsQuicks: [{ key: "send_by" }, { key: "number" }, { key: "content" }, { key: "image" }],
      items: [],
      images: [],
      indexImage: null
    };
  },
  methods: {
    async getHistorySms() {
      try {
        this.isBusy = true;
        const response = await ClientService.allSmsHistory({ id: this.id , type: 0 });
        if (response.status == 200) {
          this.items = response.data;
          this.getImages(response.data);
        } else
          this.showToast(
              "warning",
              "top-right",
              "Warning!",
              "AlertTriangleIcon",
              response.message
          );
        this.isBusy = false;
      } catch (error) {
        console.log("Something went wrong getHistorySms:", error);
        this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
        );
      }
    },
    getImages(items) {
      items.forEach(item => {
        if (item.image) {
          this.images.push(item.image);
        }
      });
    }
  },
  created() {
    this.userId = this.currentUser.user_id;
    this.roleId = this.currentUser.role_id;
    this.getHistorySms();
  }
};
</script>