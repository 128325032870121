<template>
  <div class="p-1">
    <!-- TABS FOR STATUS -->
    <b-nav card-header pills class="m-0">
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          [8, 9, 10].includes(statusNav) ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="[8, 9, 10].includes(statusNav)"
        @click="changeNav(8)"
      >
        Active
        <span class="ml-2"
          ><feather-icon
            icon
            :badge="status[0].total"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          [11, 12, 13].includes(statusNav) ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="[11, 12, 13].includes(statusNav)"
        @click="changeNav(11)"
      >
        Loyal
        <span class="ml-2"
          ><feather-icon
            icon
            :badge="status[1].total"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          [2, 4, 6].includes(statusNav) ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        @click="changeNav(2)"
        :active="[2, 4, 6].includes(statusNav)"
      >
        Outstanding
        <span class="ml-2"
          ><feather-icon
            icon
            :badge="status[2].total"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
      <filter-slot
        :filter="visibleFilters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        change-columns-by-client
        :to-page="toPage"
        @reset-all-filters="resetAllFilters()"
        @reload="$refs.clientsList.refresh()"
      >
        <template #buttons>
          <div class="d-flex justify-content-between align-items-center w-80">
            <!-- Button Send Email -->
            <b-button
              variant="success"
              class="ml-1"
              :disabled="lengthClients"
              @click="openModalSendEmail"
            >
              <feather-icon icon="MailIcon" class="mr-50" />Send Email
            </b-button>
            <div>
              <b-button
                v-for="showStatus in showStatus"
                :key="showStatus.id"
                :variant="
                  showStatus.id == statusNav ? 'primary' : 'outline-primary'
                "
                class="ml-1"
                :disabled="showStatus.id == statusNav"
                @click="changeNav(showStatus.id)"
                style="position: relative"
              >
                {{ showStatus.name }}
                <span class="position-absolute" style="top: 0; right: 0">
                  <feather-icon
                    icon
                    :badge="showStatus.total"
                    badge-classes="badge-danger"
                  />
                </span>
              </b-button>
            </div>
          </div>
        </template>
        <template #custom-vselect>
          <FilterStatusAccount :filters="filters[4]" />
        </template>
        <b-table
          ref="clientsList"
          slot="table"
          :busy.sync="isBusy"
          :fields="visibleFields"
          :items="myProvider"
          :filter="visibleFilters"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          no-provider-filtering
          responsive="sm"
          small
          show-empty
          sticky-header="65vh"
        >
          <!-- COLUMNS SELECTED -->
          <template #head(selected)>
            <b-form-checkbox v-model="selectAll" @input="selectedAll" />
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>

          <!-- COLUMNS SELECT -->
          <template #cell(selected)="data">
            <b-form-group>
              <b-form-checkbox
                v-model="clientsSms"
                :value="{
                  id: data.item.account_id,
                  name: data.item.lead_name,
                }"
                :name="data.item.account_id"
                class="mt-1"
              />
            </b-form-group>
          </template>

          <!-- COLUMN LEAD NAME -->
          <template #cell(lead_name)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <router-link
                class
                target="_blank"
                :to="`/bussiness/clients/account/${data.item.account_id}`"
              >
                {{ data.item.lead_name }}
              </router-link>
              <div v-if="data.item.mobile">
                <feather-icon icon="PhoneIcon" size="16" class="text-warning" />
                {{ data.item.mobile }}
              </div>
              <div>
                <status-account
                  :account="data.item"
                  :text="true"
                ></status-account>
              </div>
              <span v-if="data.item.user_responsible" class="text-warning">
                <tabler-icon
                  :icon="
                    data.item.user_responsible === currentUser.user_id
                      ? 'StarIcon'
                      : 'LockIcon'
                  "
                  size="15"
                  style="margin-left: 5px"
                />
                <strong>CEO's client</strong>
              </span>
              <b-badge
                v-if="data.item.first_current_note === 0"
                variant="light-info"
              >
                First note [ Pending update ]
              </b-badge>
              <!-- <b-button
                size="sm"
                v-if="data.item.total_canceled > 0"
                variant="outline-info"
              >
                Canceled RB {{ data.item.total_canceled }}
              </b-button> -->
            </div>
          </template>

          <!-- COLUMN ACCOUNT -->
          <template #cell(account)="data">
            <div>
              {{ data.item.account }}
            </div>
            <div>
              {{ data.item.mobile }}
            </div>
            <div>
              <b-badge v-if="data.item.claims != 0" variant="light-info">
                IN CLAIM ({{ data.item.claims }})
              </b-badge>
            </div>
          </template>
          <!-- COLUMN FEE CHARGES -->
          <template #cell(fee_charges)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
              style="padding-bottom: 4px"
            >
              {{ data.item.charges | formatCurrency }}
            </div>
          </template>
          <!-- COLUMN MP -->
          <template #cell(mp)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
              style="padding-bottom: 4px"
            >
              {{ data.item.monthly_amount | formatCurrency }}
            </div>
          </template>

          <!-- COLUMN LP -->
          <template #cell(lp)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
              style="position: relative"
            >
              <span style="display: flex">
                <b-img
                  v-if="data.item.state_lp == 1"
                  :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-out.ico`"
                  style="
                    margin-right: 5px;
                    height: 15px;
                    margin-top: 2px;
                    width: 15px;
                  "
                />
                <b-img
                  v-else
                  :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-in.ico`"
                  style="
                    margin-right: 5px;
                    height: 15px;
                    margin-top: 2px;
                    width: 15px;
                  "
                />
                {{
                  data.item.last_payment == "-" ? "" : data.item.last_payment
                }}
              </span>
            </div>
          </template>

          <!-- COLUMN MP -->
          <template #cell(pt)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <status-payment :account="data.item" />
                </li>
              </ul>
            </div>
          </template>

          <!-- COLUMN ADVISOR -->
          <template #cell(advisor)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
            >
              <span style="padding-bottom: 4px">
                <feather-icon
                  v-if="data.item.state_advisor === 1"
                  icon="CircleIcon"
                  size="13"
                  :style="`color: #00CC00; border-color: #00CC00; background: #00CC00; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                />
                <feather-icon
                  v-if="data.item.state_advisor === 2"
                  icon="CircleIcon"
                  size="13"
                  :style="`color: #00CC00; border-color: #00CC00; background: #00CC00; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                />
                <feather-icon
                  v-if="data.item.state_advisor === 0"
                  icon="CircleIcon"
                  size="13"
                  :style="`color: rgb(204, 204, 204); border-color: #00CC00; background: rgb(204, 204, 204); border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                />
                <span>{{ data.item.advisor_name }}</span></span
              >
            </div>
          </template>

          <!-- COLUMN LT -->
          <template #cell(ln)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
            >
              <span>{{ data.item.last_note }} </span>
            </div>
          </template>

          <!-- COLUMN UT -->
          <template #cell(ut)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
              style="margin-left: 10px"
            >
              <span
                :style="
                  data.item.task <= 5
                    ? 'color:#000000; position: relative;'
                    : data.item.task <= 10
                    ? 'color: #FF8000; position: relative;'
                    : 'color:#FF0000; position: relative;'
                "
              >
                <b-img
                  :src="`${baseImg}/assets/images/paragon/tasks/uncompletetasks.ico`"
                  style="
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  "
                />
                <span
                  style="font-size: 11px"
                  :style="isDarkSkin ? 'color: #fff' : ''"
                  >{{ data.item.task }}</span
                >
              </span>
            </div>
          </template>

          <!-- COLUMN CREATION DATE -->
          <template #cell(creation_date)="data">
            <span>{{ data.item.created_at | myGlobal }}</span>
          </template>

          <!-- Column SERVICES -->
          <template
            v-if="
              currentUser.role_id == 1 ||
              currentUser.role_id == 2 ||
              currentUser.role_id == 3
            "
            #cell(services)="data"
          >
            <b-dropdown
              v-if="
                data.item.user_responsible == currentUser.user_id ||
                data.item.user_responsible == null
              "
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- Add Service -->
              <b-dropdown-item
                variant="warning"
                @click="modalAddService(data.item)"
              >
                <feather-icon icon="FilePlusIcon" />
                <span class="align-middle ml-50">Add service</span>
              </b-dropdown-item>

              <!-- Change Service -->
              <b-dropdown-item
                :variant="'success'"
                @click="modalChangeService(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Change service</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <!-- COLUMN OTHER PROGRAMS -->
          <template #cell(qty_services)="{ item }">
            <ClientPrograms
              :clientData="item"
              :programName="item.program"
              :programId="item.program_id"
            />
          </template>

          <!-- Column Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              v-if="
                data.item.user_responsible == currentUser.user_id ||
                data.item.user_responsible == null
              "
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- historySMS -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'History of SMS'"
                v-b-modal.modal-primary
                @click="openModalHistorySms(data.item)"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span class="align-middle ml-50">History of SMS</span>
              </b-dropdown-item>

              <!-- historyEMAIL -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'History of Email'"
                v-b-modal.modal-primary
                @click="openModalHistoryEmail(data.item)"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span class="align-middle ml-50">History of Email</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <!-- Modal send sms -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="clientsSms[0]"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Send Email -->
    <modal-send-email
      v-if="modalSendEmail"
      :modalSendEmail="modalSendEmail"
      :nameLeads="clientsSms"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />

    <!-- modal HISTORY SMS -->
    <b-modal
      id="modal-history-sms"
      ok-only
      modal-class="modal-primary"
      title-class="text-white h4"
      centered
      size="lg"
      title="History of Sms"
      hide-footer
    >
      <modal-history-sms
        :id="historySms.id"
        :account-name="historySms.account_name"
        :lead-name="historySms.leadName"
      />
    </b-modal>

    <!-- modal HISTORY Email -->
    <b-modal
      id="modal-history-email"
      ok-only
      modal-class="modal-primary"
      title-class="text-white h4"
      centered
      size="lg"
      title="History of Email"
      hide-footer
    >
      <modal-history-email
        :id="historyEmail.id"
        :account-name="historyEmail.account_name"
        :lead-name="historyEmail.leadName"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import FieldsBusinessClients from "../data/fields.data";
import FilterBusinessClients from "../data/filter.data";
import ClientServiceBusiness from "../services/clients.service";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";

import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import ModalAddService from "@/views/commons/components/digital/modals/ModalAddService.vue";
import ModalHistoryEmail from "@/views/commons/components/digital/modals/ModalHistoryEmail.vue";
import ModalHistorySms from "@/views/commons/components/digital/modals/ModalHistorySMS.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
import ClientPrograms from "@/views/commons/components/client-programs/ClientPrograms.vue";
import { modifyVisibility } from "@/helpers/fields-table";

export default {
  name: "ClientsGrid",
  components: {
    StatusPayment,
    ModalSendEmail,
    ModalSendSms,
    ModalAddService,
    ModalHistoryEmail,
    ModalHistorySms,
    StatusAccount,
    NcrLeadsService,
    FilterStatusAccount,
    ClientPrograms,
  },
  data() {
    return {
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      urlOriginalSoft: process.env.VUE_APP_ORIGINAL_SOFT,
      fields: FieldsBusinessClients,
      filters: FilterBusinessClients,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      toPage: null,
      clientsSms: [],
      modalSendSms: false,
      modalSendEmail: false,
      isBusy: false,
      selectAll: 0, // total
      clients: [],
      allClients: [],
      items: [],
      detailsClient: {},
      typeModal: null,
      modalOpenAddService: false,
      typeAddChange: null,
      historySms: {
        leadName: "",
        account_name: "",
        id: null,
      },
      historyEmail: {
        leadName: "",
        account_name: "",
        id: null,
      },
      statusNav: 8,
      status: [
        {
          id: 8,
          total: 0,
          subStatus: [
            { id: 8, name: "Current", total: 0 },
            { id: 9, name: "1 Month Late", total: 0 },
            { id: 10, name: "2 Month Late", total: 0 },
          ],
        },
        {
          id: 11,
          total: 0,
          subStatus: [
            { id: 11, name: "In Progress", total: 0 },
            { id: 12, name: "Potential", total: 0 },
            { id: 13, name: "Stand By", total: 0 },
          ],
        },
        {
          id: 2,
          total: 0,
          subStatus: [
            { id: 2, name: "Hold", total: 0 },
            { id: 4, name: "Canceled", total: 0 },
            { id: 6, name: "Closed", total: 0 },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFilters() {
      return this.filters.filter((filter) => filter.visible);
    },
    lengthClients() {
      return this.clientsSms.length == 0;
    },
    showStatus() {
      let statusParent = 2;

      if ([8, 9, 10].includes(this.statusNav)) {
        statusParent = 8;
      } else if ([11, 12, 13].includes(this.statusNav)) {
        statusParent = 11;
      }
      const status = this.status.find((status) => status.id === statusParent);
      return status?.subStatus;
    },
    visibleFields() {
      modifyVisibility(this.fields, "selected", this.currentUser.role_id !== 4);
      modifyVisibility(
        this.fields,
        "advisor",
        this.currentUser.role_id !== 3 || this.module_id === 2
      );
      modifyVisibility(
        this.fields,
        "services",
        this.currentUser.role_id === 1 ||
          this.currentUser.role_id === 2 ||
          this.currentUser.role_id === 3
      );
      modifyVisibility(this.fields, "actions", this.currentUser.role_id !== 4);
      return this.fields.filter((column) => column.visible);
    },
    paymentDay: {
      get() {
        return this.filters[5].model;
      },
      set(value) {
        this.filters[5].visible = value;
      },
    },
    paymentType: {
      get() {
        return this.filters[4].model;
      },
      set(value) {
        this.filters[4].model = value;
      },
    },
    moduleList() {
      let module_id = 1;
      return module_id;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    pathNameSaleMade() {
      return "sales-made-bussiness-client-pending";
    },
  },
  watch: {
    paymentType(newVal) {
      if (newVal === 1) {
        this.paymentDay = true;
      } else {
        this.paymentDay = false;
        this.filters[5].model = 0;
      }
    },
  },
  created() {
    this.usersPrograms();
    this.counterClients();
  },
  methods: {
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    async counterClients() {
      const response = await ClientServiceBusiness.counterClients();
      response.forEach((element) => {
        this.status.forEach((status) => {
          status.subStatus.forEach((subStatus) => {
            if (subStatus.id == element.status) {
              subStatus.total = element.count;
            }
          });
        });
      });
      this.status[0].total = this.status[0].subStatus.reduce(
        (acc, subStatus) => acc + subStatus.total,
        0
      );
      this.status[1].total = this.status[1].subStatus.reduce(
        (acc, subStatus) => acc + subStatus.total,
        0
      );
      this.status[2].total = this.status[2].subStatus.reduce(
        (acc, subStatus) => acc + subStatus.total,
        0
      );
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    modalAddService: async function (data) {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        await this.createEvent(data);
        this.modalGeneralService(data, 3);
      }
    },
    modalChangeService: async function (data) {
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        this.typeAddChange = 2;
        await this.createEvent(data);
        this.modalGeneralService(data, 4);
      }
    },
    openModalHistorySms(item) {
      this.historySms.id = item.account_id;
      this.historySms.account_name = item.id;
      this.historySms.leadName = item.lead_name;
      this.$bvModal.show("modal-history-sms");
    },
    openModalHistoryEmail(item) {
      this.historyEmail.id = item.account_id;
      this.historyEmail.account_name = item.id;
      this.historyEmail.leadName = item.lead_name;
      this.$bvModal.show("modal-history-email");
    },
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.detailsClient.accounts = [
        {
          program: data.program,
        },
      ];
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    async usersPrograms() {
      try {
        const paramsForBusinness = {
          idmodule: this.moduleId,
          iduser: this.currentUser.user_id,
          idrole: this.currentUser.role_id,
        };
        const dataBusinness = await ClientServiceBusiness.usersPrograms(
          paramsForBusinness
        );
        this.filters[3].options = dataBusinness;
      } catch (error) {
        console.log(error);
      }
    },
    openModalSendSms() {
      this.modalSendSms = true;
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    resetAllFilters() {
      this.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.clientsList.refresh();
    },
    openModalProgram: async function (data) {
      if (data === undefined) return;
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: this.detailsClient.account_id,
            captured: data.captured.id,
            seller: data.seller.id,
            type: this.typeAddChange,
            user_id: this.currentUser.user_id,
            module: this.moduleId,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            let result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              this.$router
                .push({ name: this.pathNameSaleMade })
                .catch((err) => {
                  console.log(err);
                });
            }
          } catch (error) {
            this.showErrorSwal(
              "Please, verify if the client already has the service, otherwise contact support.",
              "Error"
            );
          } finally {
            this.removePreloader();
          }
        }
      }
    },
    async changeNav(value) {
      this.statusNav = value;
      this.$refs.clientsList.refresh();
    },
    async myProvider(ctx) {
      try {
        let orderBy = 5;
        let sortDirection = "desc";
        if (ctx.sortBy.length > 0) {
          const columnTable = this.fields.find((x) => x.key === ctx.sortBy);
          orderBy = columnTable.orderBy;
          sortDirection = ctx.sortDesc ? "desc" : "asc";
        }
        const params = {
          per_page: this.paginate.perPage,
          orderby: orderBy,
          order: sortDirection,
          from: this.filters[0].model,
          to: this.filters[1].model,
          text: this.filterPrincipal.model,
          state: this.state,
          status: this.statusNav,
          advisor:
            this.currentUser.role_id == 1 || this.currentUser.role_id == 2
              ? this.filters[3].model
              : null,
          type: this.paymentType,
          day: this.paymentDay,
          rol_id: this.currentUser.role_id,
          program: this.moduleList,
          page: ctx.currentPage,
        };
        const response = await ClientServiceBusiness.search(params);
        const items = response.data.map((item) => ({
          ...item,
          ...(item.transactions
            ? JSON.parse(item.transactions)
            : {
                last_payment: null,
                state_lp: null,
                last_payment_unformatted: null,
              }),
          ...(item.notes_accounts
            ? JSON.parse(item.notes_accounts)
            : { oln: null, last_note: null, s_note: null }),
          claims: Number(item.claims),
          // charges: this.formatMoney(item.chargesn ?? 0),
          charges: item.chargesn ?? 0,
          // monthly_amount: this.formatMoney(item.monthly_amount ?? 0),
          monthly_amount: item.monthly_amount ?? 0,
        }));

        this.items = items;
        this.clients = items;
        this.startPage = response.from;
        this.paginate.currentPage = response.current_page;
        this.paginate.perPage = response.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.last_page;
        this.totalData = response.total;
        this.totalRows = response.total;
        this.toPage = response.to;
        return this.items;
      } catch (ex) {
        console.log(ex);
        return [];
      }
    },
    selectedAll() {
      if (this.selectAll == 1) {
        let nameCl = [];
        this.clients.map((dato) => {
          nameCl.push({
            id: dato.account_id,
            name: dato.lead_name,
          });
        });
        let value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (!value) {
          this.allClients.push({
            currentPage: this.paginate.currentPage,
            array: nameCl,
          });
        }
      }
      if (this.selectAll == 0) {
        const value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (value) {
          const index = this.allClients.indexOf(value);
          this.allClients.splice(index, 1);
        }
      }
      this.clientsSms = [];
      for (const client of this.allClients) {
        this.clientsSms = [...this.clientsSms, ...client.array];
      }
    },
    formatMoney(value) {
      const formater = new Intl.NumberFormat("en-US", {
        currency: "USD",
        style: "currency",
      }).format(Number(value));
      return formater;
    },
  },
  mounted() {},
};
</script>

<style>
.activeCustom a {
  background: #8f5fe8 !important;
  background-color: #8f5fe8 !important;
  color: white !important;
}
.w-80 {
  width: 80% !important;
}
</style>
