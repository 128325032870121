const FieldsBusinessClients = [
  {
    key: 'selected',
    label: '',
    sortable: false,
    visible: true
  },
  {
    key: 'lead_name',
    label: 'Name',
    sortable: true,
    visible: true,
    orderBy: 2
  },
  {
    key: 'mobile',
    label: 'Mobile',
    sortable: false,
    visible: false
  },
  {
    key: 'account',
    label: 'Account',
    sortable: true,
    visible: true,
    orderBy: 4
  },
  {
    key: "qty_services",
    label: "Others",
    sortable: false,
    visible: true,
    class: "text-center",
  },
  {
    key: 'status',
    label: 'Status',
    sortable: false,
    visible: false
  },
  {
    key: 'fee_charges',
    label: 'Fee & Charges',
    sortable: true,
    visible: true,
    orderBy: 42
  },
  {
    key: 'mp',
    label: 'MP',
    sortable: true,
    visible: true,
    orderBy: 43
  },
  {
    key: 'lp',
    label: 'LP',
    sortable: true,
    visible: true,
    orderBy: 44
  },
  {
    key: 'pt',
    label: 'PT',
    sortable: true,
    visible: true,
    orderBy: 48
  },
  {
    key: 'advisor',
    label: 'Advisor',
    sortable: false,
    visible: true
  },
  {
    key: 'ln',
    label: 'LN',
    sortable: true,
    visible: true,
    orderBy: 45
  },
  {
    key: 'ut',
    label: 'UT',
    sortable: true,
    visible: true,
    orderBy: 46
  },
  {
    key: 'creation_date',
    label: 'Creation Date',
    sortable: true,
    visible: true,
    orderBy: 5
  },
  {
    key: 'services',
    label: 'Services',
    sortable: false,
    visible: true
  },
  {
    key: 'actions',
    label: 'Actions',
    sortable: false,
    visible: true
  },
];
export default FieldsBusinessClients;
