export default [
    {
        key: 'selected',
        label: '',
        sortable: false,
        visible: true
    },
    {
        key: 'lead_name',
        label: 'Name',
        sortable: true,
        visible: true
    },
    {
        key: 'mobile',
        label: 'Mobile',
        sortable: false,
        visible: true
    },
    {
        key: 'account',
        label: 'Account',
        sortable: true,
        visible: true
    },
    {
        key: 'status',
        label: 'Status',
        sortable: false,
        visible: true
    },
    {
        key: 'fee_charges',
        label: 'Fee & Charges',
        sortable: true,
        visible: true
    },
    {
        key: 'mp',
        label: 'MP',
        sortable: true,
        visible: true
    },
    {
        key: 'lp',
        label: 'LP',
        sortable: true,
        visible: true
    },
    {
        key: 'pt',
        label: 'PT',
        sortable: true,
        visible: true
    },
    {
        key: 'advisor',
        label: 'Advisor',
        sortable: false,
        visible: true
    },
    {
        key: 'ln',
        label: 'LN',
        sortable: true,
        visible: true
    },
    {
        key: 'ut',
        label: 'UT',
        sortable: true,
        visible: true
    },
    {
        key: 'creation_date',
        label: 'Creation Date',
        sortable: true,
        visible: true
    },
    {
        key: 'services',
        label: 'Services',
        sortable: false,
        visible: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false,
        visible: true
    },
];